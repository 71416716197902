import {
  ApolloClient,
  ApolloQueryResult,
  DocumentNode,
  NormalizedCacheObject
} from '@apollo/client'
import { SectionCollectionId } from 'utils'
import {
  GetAccordionByIdDocument,
  GetAlternatingContentByIdDocument,
  GetAlternatingQuoteByIdDocument,
  GetBulletPointsByIdDocument,
  GetEmotionByIdDocument,
  GetFormLeadCaptureByIdDocument,
  GetGalleryCarouselByIdDocument,
  GetGenericTextByIdDocument,
  GetImpactTextByIdDocument,
  GetIntroTextWithJumpMenuDocument,
  GetJumpAheadByIdDocument,
  GetKeepExploringByIdDocument,
  GetMapContainerByIdDocument,
  GetMediaImageByIdDocument,
  GetMediaVideoByIdDocument,
  GetNextStepsByIdDocument,
  GetRegionCardsCarouselByIdDocument,
  GetStaticComponentByIdDocument,
  GetStatisticsByIdDocument,
  GetStatsImageCarouselByIdDocument,
  GetStoryCardContainerByIdDocument,
  GetTilesByIdDocument,
  GetValueComponentByIdDocument
} from '../index'

export enum ElementTypenames {
  AlternatingComponent = 'SWNZ_AlternatingContent',
  AlternatingQuote = 'SWNZ_AlternatingQuote',
  GalleryCarousel = 'SWNZ_GalleryCarousel',
  EmotionContainer = 'SWNZ_EmotionContainer',
  NextSteps = 'SWNZ_NextSteps',
  ImpactText = 'SWNZ_ImpactText',
  Statistics = 'SWNZ_StatContainer',
  Tiles = 'SWNZ_TileContainer',
  GenericText = 'SWNZ_GenericText',
  MediaVideo = 'SWNZ_MediaVideo',
  MediaImage = 'SWNZ_MediaImage',
  LeadCaptureForm = 'SWNZ_FormLeadCapture',
  RegionCardsCarousel = 'SWNZ_CardContainer',
  KeepExploring = 'SWNZ_KeepExploring',
  StoryCardContainer = 'SWNZ_StoryCardContainer',
  BulletPoints = 'SWNZ_BulletPointContainer',
  AccordionContainer = 'SWNZ_AccordionContainer',
  JumpAhead = 'SWNZ_JumpAhead',
  IntroTextWithJumpMenu = 'SWNZ_IntroTextWithJumpMenu',
  Promo = 'SWNZ_Promo',
  Value = 'SWNZ_Value',
  MapContainer = 'SWNZ_MapContainer',
  ComponentStatic = 'SWNZ_ComponentStatic',
  StatsImageCarousel = 'SWNZ_StatsImageCarousel'
}

export const elementQueryMap: Readonly<{ [typename: string]: DocumentNode }> =
  Object.freeze({
    [ElementTypenames.EmotionContainer]: GetEmotionByIdDocument,
    [ElementTypenames.AlternatingComponent]: GetAlternatingContentByIdDocument,
    [ElementTypenames.AlternatingQuote]: GetAlternatingQuoteByIdDocument,
    [ElementTypenames.GalleryCarousel]: GetGalleryCarouselByIdDocument,
    [ElementTypenames.NextSteps]: GetNextStepsByIdDocument,
    [ElementTypenames.ImpactText]: GetImpactTextByIdDocument,
    [ElementTypenames.Statistics]: GetStatisticsByIdDocument,
    [ElementTypenames.Tiles]: GetTilesByIdDocument,
    [ElementTypenames.GenericText]: GetGenericTextByIdDocument,
    [ElementTypenames.MediaVideo]: GetMediaVideoByIdDocument,
    [ElementTypenames.MediaImage]: GetMediaImageByIdDocument,
    [ElementTypenames.RegionCardsCarousel]: GetRegionCardsCarouselByIdDocument,
    [ElementTypenames.KeepExploring]: GetKeepExploringByIdDocument,
    [ElementTypenames.StoryCardContainer]: GetStoryCardContainerByIdDocument,
    [ElementTypenames.BulletPoints]: GetBulletPointsByIdDocument,
    [ElementTypenames.AccordionContainer]: GetAccordionByIdDocument,
    [ElementTypenames.JumpAhead]: GetJumpAheadByIdDocument,
    [ElementTypenames.IntroTextWithJumpMenu]: GetIntroTextWithJumpMenuDocument,
    [ElementTypenames.Value]: GetValueComponentByIdDocument,
    [ElementTypenames.LeadCaptureForm]: GetFormLeadCaptureByIdDocument,
    [ElementTypenames.MapContainer]: GetMapContainerByIdDocument,
    [ElementTypenames.ComponentStatic]: GetStaticComponentByIdDocument,
    [ElementTypenames.StatsImageCarousel]: GetStatsImageCarouselByIdDocument
  })

async function connectorElementQueries(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  elementData: Array<SectionCollectionId | null | undefined> | void,
  locale?: string | null,
  preview?: boolean | null
) {
  const elementQueries: Array<Promise<ApolloQueryResult<any>>> = []
  if (!elementData) {
    return null
  }

  // Iterate through elementData, validate individual elements, if element is valid push its
  // respective query to the elementQueries array.
  for (const item of elementData) {
    if (!item) {
      continue
    }
    const elementId = item.sys?.id
    const elementQuery = elementQueryMap[`${item.__typename}`]

    if (elementQuery && elementId) {
      elementQueries.push(
        apolloClient.query({
          query: elementQuery,
          variables: { id: elementId, locale, preview }
        })
      )
    }
  }

  // Await for all the queries in elementQueries array to be either fulfilled or rejected.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
  // Filter responses for fullfilled responses containing data, emit error log for fullfilled responses
  // NOT containing data.
  try {
    const response = await Promise.allSettled(elementQueries)
    return response.filter((queryResponse, index) => {
      if (queryResponse.status !== 'fulfilled') {
        console.error({ queryResponse })
      }

      if (
        queryResponse.status === 'fulfilled' &&
        Object.values(queryResponse.value.data)[0] === null
      ) {
        return console.warn(
          `⚠️  Null response for element query: ${JSON.stringify(
            elementData[index]
          )}`
        )
      }

      if (queryResponse.status === 'fulfilled') {
        return queryResponse
      }
    })
  } catch (error) {
    console.error('ERROR')
  }
}

export { connectorElementQueries }
